import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getFullName } from "utils";
import { useState, useEffect } from "react";
import { Constants } from "config/constants";
import { verifyPaymentAsync } from "state/slices/payment";

import { checkTransactionTokenAsync } from "state/slices/payment";

const PublicLimitedCompanyPage = () => {
  const sflData = Constants.SFL_DATA;
  const [billingData, setBillingData] = useState(null);
  const [hasLoadingStartedForFirstTime, setHasStartedLoadingForFirstTime] =
    useState(false);
  const userSlice = useSelector((state) => state.userSlice);
  const authSlice = useSelector((state) => state.authSlice);
  const dispatch = useDispatch();
  const history = useHistory();
  const params = new URLSearchParams(history.location.search);

  useEffect(async () => {
    const token = await dispatch(
      checkTransactionTokenAsync({
        data: {
          companyType:
            params.get("company") === "PLC"
              ? "Public Limited Company"
              : "Private Company",
        },
      })
    );
    const { paymentTransaction } = token.payload;
    console.log({ paymentTransaction });
    setBillingData(paymentTransaction.invoice);
  }, []);

  useEffect(() => {
    if (authSlice.getUserStatus.status === "loading")
      setHasStartedLoadingForFirstTime(true);
    if (
      hasLoadingStartedForFirstTime &&
      authSlice.getUserStatus.status === "idle" &&
      !!billingData
    ) {
      handleCardPaymentResponse();
    }
  }, [authSlice.getUserStatus.status, billingData]);

  const handleCardPaymentResponse = async () => {
    setHasStartedLoadingForFirstTime(false);
    const invoice = {
      plan: params.get("plan"),
      billFrom: {
        name: sflData.name,
        address: `${sflData.buildingName}, ${sflData.floorNumber}, ${sflData.streetName}, ${sflData.location.county}`,
        pin: sflData.pin,
      },
      billTo: {
        name: `${billingData.firstName} ${billingData.lastName}`,
        address: billingData.billingAddress,
        pin: billingData.KRAPin || "N/A",
      },
      items: [
        {
          description: params.get("itemDescription"),
          quantity: 1,
          actualAmount: Number(params.get("itemActualAmount")),
          discountAmount: Number(params.get("itemDiscountAmount")),
          rate:
            Number(params.get("itemActualAmount")) -
            Number(params.get("itemDiscountAmount")),
        },
      ],
      currency: params.get("currency"),
      isTaxApplicable: Number(params.get("applicableTax")) > 1,
      applicableTax: Number(params.get("applicableTax")),
      paymentMethod: params.get("paymentMethod"),
      firstName: billingData?.firstName ?? "",
      email: billingData?.email ?? "",
    };

    const payload = {
      company: { type: params.get("company") },
      orderId: params.get("orderId"),
      invoice,
    };
    // return;
    const verifyPaymentResponse = await dispatch(
      verifyPaymentAsync({
        data: {
          TransID: params.get("TransID"),
          payload,
        },
      })
    );
    const { redirectUrl } = verifyPaymentResponse.payload;
    console.log({ response: verifyPaymentResponse.payload });
    // return
    history.push(redirectUrl);
    // setIsSubmitting(false);
  };

  return (
    <>
      <div className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center bg-white z-50">
        <div className="bg-white">
          <div className="flex flex-col items-center justify-between space-x-4 h-60 rounded-md shadow-md p-8">
            <div className="w-58">
              <img
                src="https://res.cloudinary.com/duqjf9tj1/image/upload/v1639701540/Assets/sfl_bxbwqe.svg"
                alt="SFL Logo"
                className="w-full"
              />
            </div>
            <div className="flex space-x-2">
              <svg
                className="animate-spin w-8 text-green-500"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5a8 8 0 018-8h4c0 6.627-5.373 12-12 12v-4zM6 12a6 6 0 016-6V0C7.925 0 2 5.373 2 12h4zm2 5a6 6 0 016-6h4c0 3.313-2.687 6-6 6v-6zm2 5a4 4 0 004-4h-4v4z"
                ></path>
              </svg>
              <p className="text-gray-700 text-lg">
                Please wait! we are verifying your payment
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PublicLimitedCompanyPage;
