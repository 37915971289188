import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { ChevronDoubleLeftIcon } from "@heroicons/react/solid";
import Hero from "components/lib/Global/Hero";
import DashboardWrapper from "components/lib/Global/DashboardWrapper";
import BusinessDetails from "components/CompanyDashboard/BusinessDetails";
import MinuteBook from "components/CompanyDashboard/MinuteBook";
import TransactionsList from "components/CompanyDashboard/TransactionsList";
import CompaniesList from "components/CompanyDashboard/CompaniesList";
import MaintainYourCompany from "components/CompanyDashboard/MaintainYourCompany";
import SpinnerIcon from "components/lib/Shared/Icons/SpinnerIcon";
import DashboardAccessRequests from "components/CompanyDashboard/DashboardAccessRequests";
import MainSidebar from "./MainSidebar";
import MinuteBookSidebar from "./MinuteBookSidebar";
import { MinuteBookContext } from "context/MinuteBookContext";
import { minuteBookSidebarItems } from "./minuteBookSidebarItems";
import { flattenNestedChildren } from "utils";
import { getAccessRequests } from "state/slices/accessRequest";
import {
  getCompanyBeneficialOwnersAsync,
  getCompanyDashboardAsync,
  getCompanyOfficialsAsync,
  resetCompanyDashboard,
} from "state/slices/companyDashboard";

const defaultTab = { id: "businessDetails", name: "Business Details" };

const CompanyDashboardPage = () => {
  const { companyId } = useParams();
  const dispatch = useDispatch();
  const { getUserStatus } = useSelector((state) => state.authSlice);
  const { company, getCompanyStatus, breadCrumbs } = useSelector(
    (state) => state.companyDashboardSlice
  );

  const [selectedTab, setSelectedTab] = useState(defaultTab);
  const currentUser = useSelector((state) => state.userSlice);

  useEffect(() => {
    if (companyId && currentUser?._id) {
      dispatch(getCompanyDashboardAsync({ companyId }));
      dispatch(
        getCompanyOfficialsAsync({
          companyId,
          queryParams: { isDashboard: true },
        })
      );
      dispatch(getCompanyBeneficialOwnersAsync({ companyId }));
      dispatch(getAccessRequests({ query: { createdBy: currentUser._id } }));
    }
    return () => {
      dispatch(resetCompanyDashboard());
    };
  }, [companyId, currentUser?._id, dispatch]);

  const companyName = company?.registrationName ?? company?.companyName1;

  let pages =
    breadCrumbs.length > 0
      ? [
          { name: "My Companies", href: "/companies", current: false },
          ...breadCrumbs,
        ]
      : [
          { name: "My Companies", href: "/companies", current: false },
          companyName
            ? {
                name: companyName,
                href: "#",
                current: true,
              }
            : null,
        ];

  const goToBusinessDetails = () =>
    setSelectedTab({ id: "businessDetails", name: "Business Details" });

  const handleMakeApplication = () => {
    setSelectedTab({
      id: "transferYourBusiness",
      name: "Transfer Your Business",
    });
  };

  return (
    <DashboardWrapper pages={pages} showBreadCrumbs>
      <MinuteBookContext.Provider
        value={{
          selectedTab,
          setSelectedTab,
          individualSidebarItems: flattenNestedChildren(minuteBookSidebarItems),
        }}
      >
        {currentUser && currentUser?.isOnboarded && (
          <div className="grid gap-10 py-10 mx-auto">
            <Hero
              heading={
                company?.registrationName ??
                selectedTab?.label ??
                selectedTab?.name
              }
              description=""
            />
            <div className="grid grid-cols-4 border-t border-gray">
              {selectedTab.id === "minuteBook" ||
              selectedTab?.renderMinuteBook ? (
                <div className="col-span-4 md:col-span-1 pt-8">
                  <div
                    className="flex items-center my-2 text-gray-600"
                    onClick={() => setSelectedTab(defaultTab)}
                  >
                    <ChevronDoubleLeftIcon className="w-5 h-5 mr-2" />
                    <span className="cursor-pointer">Back to main sidebar</span>
                  </div>
                  <MinuteBookSidebar
                    selectedTab={selectedTab}
                    setSelectedTab={setSelectedTab}
                    goToBusinessDetails={goToBusinessDetails}
                  />
                </div>
              ) : (
                <MainSidebar
                  selectedTab={selectedTab}
                  setSelectedTab={setSelectedTab}
                />
              )}
              <div className="col-span-4 md:border-l md:col-span-3 md:pl-11 pt-8 border-gray">
                {getUserStatus.status === "loading" ||
                getCompanyStatus.status === "loading" ? (
                  <div className="flex justify-center mx-auto text-white">
                    <SpinnerIcon className="text-gray-400" />
                  </div>
                ) : (
                  <>
                    {selectedTab.id === "businessDetails" && (
                      <BusinessDetails companyId={companyId} />
                    )}
                    {selectedTab.id === "manageYourCompany" && (
                      <MaintainYourCompany companyId={companyId} />
                    )}
                    {(selectedTab.id === "minuteBook" ||
                      selectedTab?.renderMinuteBook) && (
                      <MinuteBook selectedTab={selectedTab} />
                    )}
                    {selectedTab.id === "transactions" && <TransactionsList />}
                    {selectedTab.id === "companies" && (
                      <CompaniesList
                        goToBusinessDetails={goToBusinessDetails}
                      />
                    )}
                    {selectedTab.id === "accountSettings" && (
                      <DashboardAccessRequests />
                    )}
                  </>
                )}
              </div>
              <div>
                <button
                  className="bg-sflPrimary w-full text-white font-bold py-2 px-4 rounded mb-4"
                  onClick={handleMakeApplication}
                >
                  Make Application
                </button>
              </div>
            </div>
          </div>
        )}
      </MinuteBookContext.Provider>
    </DashboardWrapper>
  );
};

export default CompanyDashboardPage;
